import React, { useState, useEffect, useRef, useContext } from "react";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../Utils/CustomAlertMessage";
import { ProfileContext } from "../../Context";


const SupportPage = () => {
    const { email } = useContext(ProfileContext);
    const messagesEndRef = useRef(null)
    const [subject, setSubject] = useState("");
    const [message, setmessage] = useState("");
    const [myfile, setMyfile] = useState("");
    const [issueList, setIssueList] = useState([]);
    const [messageQuery, setMessageQuery] = useState([]);
    const [emailId, setEmailID] = useState([]);
    const [id, setID] = useState('');
    const [messagerply, setMessageRply] = useState('');
    const [orderId, setOrderId] = useState('')
    const [isShow, setIsShow] = useState(2);
    const [isRotating, setRotating] = useState(false);
    const [status, setStatus] = useState('');

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "subject":
                setSubject(event.target.value);
                break;
            case "orderId":
                setOrderId(event.target.value);
                break;
            case "message":
                setmessage(event.target.value);
                break;
            case "messagerply":
                setMessageRply(event.target.value);
                break;
            default:
        }
    }

    const resetInputChange = () => {
        setSubject("");
        setOrderId("");
        setmessage("");
        setMyfile();
    }

    const handleChangeImage = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        setMyfile(fileUploaded);
    }

    const handleSupport = async (email, subject, message, orderId, myfile) => {
        try {
            var formData = new FormData();
            formData.append('emailId', email);
            formData.append('subject', subject);
            formData.append('description', message);
            formData.append('order_id', orderId);
            formData.append('issue-image', myfile);
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.submitTicket(formData)
            if (result?.success) {
                alertSuccessMessage(result.message);
                resetInputChange();
                getIssueList();
            } else {
                alertErrorMessage(result.message);
            }
        } finally { LoaderHelper.loaderStatus(false); }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getIssueList();
    }, [])


    const getIssueList = async (id) => {
        LoaderHelper.loaderStatus(true);
        setMessageQuery([])
        await AuthService.getUserTickets().then(async result => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setIssueList(result?.data?.reverse());
                    if (id) {
                        let filteredData = result?.data?.filter((item) => item?._id === id);
                        let tickets = filteredData?.map((item) => item?.ticket);
                        let status = filteredData?.map((item) => item?.status);
                        setMessageQuery(tickets[0]);
                        setStatus(status[0])
                    }
                    setRotating(false);
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                setRotating(false);
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };



    const handleHidettMain = (row) => {
        setIsShow(1);
        setEmailID(row?.emailId);
        setID(row?._id);
        getIssueList(row?._id)
        setStatus(row?.status)
    };


    const handleMessageQuery = async (messagerply, id) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.replyTicket(messagerply, id).then(async result => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setMessageRply("");
                    getIssueList(id);
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.msg);
            }
        });
    }

    useEffect(() => {
        scrollToBottom()
    }, [messageQuery]);


    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView(false)
    }



    return (
        <>
            <div class="container">
                <div class="rightsidebox">
                    <div class="upload-formate mb-6 d-flex justify-content-center align-items-center">
                        <div>
                            <h3 class="mb-1 text-center">
                            Raise ticket
                            </h3>
                     
                        </div>
                    </div>
                    <div className={`tt_main ${isShow === 1 && "d-none"}`}>
                        <div class="d-flex-between mb-3 custom_dlflex">
                            <ul class="nav custom-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#profile" role="tab" >
                                        <i class="ri-file-list-2-fill me-2"></i> Issue List
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " data-bs-toggle="tab" href="#home1" role="tab">
                                        <i class="ri-ticket-fill me-2"></i>Submit Ticket
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <div class="tab-content text-left">
                            <div class="tab-pane " id="home1" role="tabpanel">
                                <div class="card-body ant-card p-md-4 p-lg-4">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                                            <label className="form-label" >Email Id</label>
                                            <input type="email" name="email" value={email}/>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                                            <label className="form-label" >Subject</label>
                                            <input type="text" name="subject" value={subject} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                                            <label className="form-label" >Order Id</label>
                                            <input type="text" name="orderId" value={orderId} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                                            <label className="form-label" >Description</label>
                                            <textarea rows="6" placeholder="Type message here" name="message" value={message} onChange={handleInputChange}></textarea>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 mb-3 ">
                                            <label className="form-label" >   <i className="ri-upload-cloud-2-line me-2"></i> Supporting documents (Attach) </label>
                                            <input type="file" onChange={handleChangeImage} />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 mb-3 d-flex justify-content-center ">
                                            <button class="btn btn-gradient w-50 justify-content-center btn-medium" type="button" onClick={() => handleSupport(email, subject, message, orderId, myfile)} disabled={!email || !message}>SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane active" id="profile" role="tabpanel">
                                <div class="inngerbox cng-pass overflow_unset">
                                    <div className={` btn_reload cursor-pointer   ${isRotating ? 'rotating' : ''}`} onClick={() => { getIssueList(); setRotating(true); }}>
                                        <i class="ri-refresh-line me-1 "></i> Reload
                                    </div>
                                    <div className='table-responsive'>
                                        <table className="table table_home ">
                                            <thead>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th>Email</th>
                                                    <th>Subject</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {issueList?.length > 0 ? issueList.map((item, index) =>
                                                    <tr key={index} className={`cursor-pointer ${(item?.seen === 0 && item?.status === 'Open') ? " font-weight-bold issue_text" : "issue_text"}`} onClick={() => handleHidettMain(item)}  >
                                                        <td >{index + 1}</td>
                                                        <td>{item?.emailId}</td>
                                                        <td > {item?.subject}</td>
                                                        <td >{item?.status} <small>{(item?.seen === 0 && item?.status === 'Open') && <i class="ri-circle-fill" style={{ color: 'green' }}></i>}</small></td>
                                                        <td > <button  className="btn btn-sm btn-primary" > Chat  </button> </td>
                                                    </tr>
                                                ) : <tr rowSpan="5">
                                                    <td colSpan="12">
                                                        <p style={{ textAlign: "center" }} className="no-data justify-content-center h-100 d-flex align-items-center">
                                                            No Data Available
                                                        </p>
                                                    </td>
                                                </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tt_chat ${isShow === 2 && "d-none"}`}>
                        <div className="row justify-content-center" >
                            <div className="col-lg-7 col-md-10">
                                <div className="d-flex align-items-center justify-content-between" >
                                    <div className="tt_header mb-4" >
                                        <h5 className="d-flex align-items-center " style={{ cursor: "pointer" }} onClick={() => { setIsShow(2); getIssueList() }}>
                                             <i class="ri-arrow-left-line mt-1  me-2 " ></i>  {emailId} </h5>
                                    </div>
                                    <div className={` btn_reload cursor-pointer    ${isRotating ? 'rotating' : ''}`} onClick={() => { getIssueList(id); setRotating(true); }}>
                                    <i class="ri-refresh-line me-1 "></i> Refresh </div>
                                </div>
                                <div className="ac_details p-3 inngerbox cng-pass overflow_unset" >
                                    <div class="right"> 
                                        {messageQuery?.length <= 0 ? <span className="issue_text " >No message found</span>
                                            :
                                            <div class="middle">
                                                <div class="tumbler chat_body scroll_y tumbler_scroll">
                                                    <div class="msg_history scroll_bar " id="message">
                                                        {messageQuery.map(item =>
                                                            <div ref={messagesEndRef} >
                                                                {item?.replyBy === 0 ?
                                                                    <div class="incoming_msg">
                                                                        <div class="received_msg"><div class="received_withd_msg"><p> {item?.query}</p></div>
                                                                        <div class="msg_time"><small>   Support Team</small></div></div>
                                                                    </div>
                                                                    :
                                                                    <div class="outgoing_msg">
                                                                        <div class="sent_msg"> <p>{item?.query}</p> </div>
                                                                        <div class="msg_time"><small> You</small></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div class="row mt-4 d-flex justify-content-center">
                                            <div className=" col-12 mt-4 ">
                                                {status === 'Open' ?
                                                    <div className=" field-otp-box msg_box_action" >
                                                        <input type="text" id="message" cols="30" rows="1" placeholder="Message..." name="messagerply" value={messagerply} onChange={handleInputChange} />

                                                        <button type="button" className="btn btn-xs  custom-btn  btn-primary " onClick={() => handleMessageQuery(messagerply, id)} disabled={!messagerply} ><span>Send</span></button>
                                                    </div>
                                                    :
                                                    <div className=" field-otp-box msg_box_action" >
                                                        <input type="text" id="message" cols="30" rows="1" name="messagerply" value='This ticket has been resolved' disabled />
                                                        <button type="button" disabled className="btn btn-xs  custom-btn btn-primary "><span>Send</span></button>
                                                    </div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default SupportPage;