import React, { useEffect, useState } from "react";
import AuthService from "../../Api/Api_Services/AuthService";
import { useOutletContext } from "react-router-dom";
import Moment from "react-moment";

const NotificationPage = () => {
    const [notificationList, setnotificationList] = useState([]);
    const [setActiveTab] = useOutletContext()

    const NotificationList = async () => {
        const result = await AuthService.notificationList()
        if (result?.success) {
            setnotificationList(result?.data?.reverse());
        }
    };

    useEffect(() => {
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)
        NotificationList()

    }, []);

    return (
        <>
            <div className="tab-pane " id="NotificationPill" role="tabpanel" aria-labelledby="Notification-pill">
                <div className="upload-formate mb-6 d-flex justify-content-center align-items-center">
                    <div>
                        <h3 className="mb-1 text-center">
                            Notification
                        </h3>
                    </div>
                </div>
                <div className="row  ">
                    <div className=" scroll_y notification_body">
                        {notificationList?.length > 0 ? notificationList?.map((item, index) => {
                            return <React.Fragment key={index}><div className="p-4 mb-2 " >
                                <div className=" row"> <h4 className="col-xl-9  col-md-12 " >{item?.title}</h4><small className="text-end col-xl-3 col-md-12 "><Moment date={item?.createdAt} format='MMMM Do YYYY, h:mm A' /> </small></div>

                                {item?.message?.length > 0 && item?.message?.map((messageData, index) => {
                                    return <div className="mt-3 d-flex" key={index}><small className="d-block" >{messageData?.description}</small><br /> </div>
                                })}
                                <div className="mt-3 d-flex" >
                                    {item?.link?.length > 0 && item?.link[0]?.Title && item?.link?.map((item, index) => {
                                        return <a href={item?.Link} target="_blank" rel="noreferrer" className="sr_content mt-3 text-underline" key={index}>
                                            {item?.Title}<i class="ri-links-line"></i>
                                        </a>
                                    })}
                                </div>
                            </div>
                                <hr /> </React.Fragment >
                        })
                            :
                            <div className="p-5 mt-10 " ><div className="d-flex flex-column justify-content-center align-items-center">
                                <img src="/images/not_found.svg" width="150px" height="90px" alt="" />
                                <small>No new notification</small>
                            </div>
                            </div>
                        }
                    </div>
                </div>
            </div >
        </>
    );
}

export default NotificationPage;