import { ApiConfig } from "../Api_Config/ApiEndpoints";
import { ApiCallGet, ApiCallPost, ApiCallPut } from "../Api_Config/ApiCall";

const AuthService = {
  /*** Calling Api's **/

  login: async (signId, password, captchaCode) => {
    const { baseAuth, login } = ApiConfig;
    const url = baseAuth + login;
    const params = {
      email_or_phone: signId,
      password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  changePassword: async (oldpassword, password, conPassword, email, otp) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, changePassword } = ApiConfig;
    const url = baseAuth + changePassword;
    const params = {
      old_password: oldpassword,
      new_password: password,
      confirm_password: conPassword,
      email: email,
      eotp: otp,
    };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };
    return ApiCallPost(url, params, headers);
  },

  register: async (signId, password, cPassword, otp, invitation) => {
    const { baseAuth, register } = ApiConfig;
    const url = baseAuth + register;
    const params = {
      email: signId,
      password: password,
      confirm_password: cPassword,
      referral_code: invitation,
      eotp: otp,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getCode: async (signId, type, otp) => {
    const { baseAuth, getcode } = ApiConfig;
    const url = baseAuth + getcode;
    const params = {
      "email_or_phone": signId,
      "type": type,
      "otp": otp
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (signid, type) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      "resend": true,
      type: type
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  withdrawalOtp: async (signId, walletAddress, withdrawAmount, selectedCurrency, type) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signId,
      amount: withdrawAmount,
      short_name: selectedCurrency,
      address: walletAddress,
      type: type,
      "resend": true,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  otpVerify: async (otp, userId, emailId, first, last, type) => {
    const { baseUrl, otpVerify } = ApiConfig;
    const url = baseUrl + otpVerify;
    const params = {
      userId: userId,
      emailId: emailId,
      firstName: first,
      lastName: last,
      motp: otp,
      otp: true,
      otpType: type,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  referralLink: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, referalcode } = ApiConfig;
    const url = baseAuth + referalcode;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },

  userReferralList: async (code) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, userReferralList } = ApiConfig;
    const url = baseAuth + userReferralList + `?code=${code}`;

    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },

  getDetails: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, getDetails } = ApiConfig;
    const url = baseAuth + getDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  activityLogs: async (skip, limit) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, activityLogs } = ApiConfig;
    const url = baseAuth + activityLogs + `?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  updateSettings: async (formData) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, updateSettings } = ApiConfig;
    const url = baseAuth + updateSettings;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPut(url, formData, headers);
  },

  googleAuth: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, googleAuth } = ApiConfig;
    const url = baseAuth + googleAuth;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  update2fa: async (authType, code, verifyType) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, update2fa } = ApiConfig;
    const url = baseAuth + update2fa;
    const params = {
      type: authType,
      verification_code: +code,
      email_or_phone: verifyType
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  getHistoricalData: async (base, quote, from, to, countBack) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: base,
      quote_currency: quote,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  getHistoricalDataFromFameEx: async (base, quote, from, to, resolution) => {
    try {
      const url = "https://api.fameex.com/v1/market/history/kline"
      const response = await fetch(url, {
        method: 'POST', headers: {}, body: JSON.stringify(
          { symbol: `${base}-${quote}`, period: resolution, startTime: from, endTime: to }
        )
      })
      return response.json()
    } catch (error) {
      return { data: [] }
    }
  },


  cryptoCompareApi: async (base, quote, to, resolution, countBack) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=${base}&tsym=${quote}&toTs=${to}&limit=2000`
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  placeOrder: async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;
    const params = {
      "order_type": infoPlaceOrder,
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "side": side,
      "price": +buyprice,
      "quantity": +buyamount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUserfunds: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseWallet, userfunds } = ApiConfig;
    const url = baseWallet + userfunds;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  generateAddress: async (currID, chain) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseWallet, generateAddress } = ApiConfig;
    const url = baseWallet + generateAddress;
    const params = {
      "currency_id": currID,
      "chain": chain
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },
  withdrawalCurrency: async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseWallet, withdrawalCurrency } = ApiConfig;
    const url = baseWallet + withdrawalCurrency;
    const params = {
      "address": walletAddress,
      "amount": +withdrawAmount,
      "chain": selectedChain,
      "currency_id": currencyId,
      "email_or_phone": signId,
      "otp": otp,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
  verifyDeposit: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseWallet, verifyDeposit } = ApiConfig;
    const url = baseWallet + verifyDeposit;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  walletDepositHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseTrans, walletDepositHistory } = ApiConfig;
    const url = baseTrans + walletDepositHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  walletWithdrawalHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseTrans, walletWithdrawalHistory } = ApiConfig;
    const url = baseTrans + walletWithdrawalHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  skipModel: async (skip) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, skipModel } = ApiConfig;
    const url = baseAuth + skipModel;
    const params = {
      check: skip,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },

  estimatedPortfolio: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseWallet, estimatedPortfolio } = ApiConfig;
    const url = baseWallet + estimatedPortfolio;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  pastOrder: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  userTradeHistory: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseExchange, userTradeHistory } = ApiConfig;
    const url = baseExchange + userTradeHistory;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  addkyc: async (formData) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAuth, addkyc } = ApiConfig;
    const url = baseAuth + addkyc;

    const headers = {
      'Content-Type': 'multipart/form-data',
      "Authorization": token,
    };
    return ApiCallPost(url, formData, headers);
  },
  tradeHistory: async (skip, limit) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseTrans, tradeHistory } = ApiConfig;
    const url = baseTrans + tradeHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  availabe_staking: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseStacking, availabe_staking } = ApiConfig;
    const url = baseStacking + availabe_staking;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  pending_staking_history: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseStacking, pending_staking_history } = ApiConfig;
    const url = baseStacking + pending_staking_history;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  staking_history: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseStacking, staking_history } = ApiConfig;
    const url = baseStacking + staking_history;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  userEligibility: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad, userEligibility } = ApiConfig;
    const url = baseLaunchpad + userEligibility;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  commitProject: async (projectName, projectSymbol, committedQuantity, projectId) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad, commitProject } = ApiConfig;
    const url = baseLaunchpad + commitProject;
    const params = { projectName, projectSymbol, committedQuantity, projectId };
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallPost(url, params, headers);
  },

  getAllProject: async (status) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad, getAllProject } = ApiConfig;
    const url = baseLaunchpad + getAllProject;
    const params = { status };
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallPost(url, params, headers);
  },

  getUserCommits: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad2, getUserCommits } = ApiConfig;
    const url = baseLaunchpad2 + getUserCommits;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  get_single_project: async (id) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad2, getSingleProject } = ApiConfig;
    const url = `${baseLaunchpad2}${getSingleProject}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  checkCommitExistence: async (id) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad, checkCommitExistence } = ApiConfig;
    const url = `${baseLaunchpad}${checkCommitExistence}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  updateCommit: async (id, committedQuantity) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad, updateCommit } = ApiConfig;
    const url = `${baseLaunchpad}${updateCommit}/${id}`;
    const params = { committedQuantity }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallPut(url, params, headers);
  },

  totalCommits: async (id) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad, totalCommits } = ApiConfig;
    const url = `${baseLaunchpad}${totalCommits}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  commitHistory: async (id) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseLaunchpad, commitHistory } = ApiConfig;
    const url = `${baseLaunchpad}${commitHistory}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  place_staking: async (currency_id, currency_Amount, selected_day) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseStacking, place_staking } = ApiConfig;
    const url = baseStacking + place_staking;
    const params = { currency_id, currency_Amount, selected_day };
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallPost(url, params, headers);
  },

  break_staking: async (transaction_id) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseStacking, break_staking } = ApiConfig;
    const url = baseStacking + break_staking;
    const params = { transaction_id };
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallPost(url, params, headers);
  },


  cancelOrder: async (orderId) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      "order_id": orderId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  getCode2: async (signid) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      signId: signid,
      type: "forgot",
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (signId, otp, password, cPassword) => {
    const { baseAuth, forgotpassword } = ApiConfig;
    const url = baseAuth + forgotpassword;
    const params = {
      email_or_phone: signId,
      verification_code: otp,
      new_password: password,
      confirm_password: cPassword,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  totalrefercount: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, totalrefercount } = ApiConfig;
    const url = baseAuth + totalrefercount;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },
  categoryList: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAdmin, categoryList } = ApiConfig;
    const url = baseAdmin + categoryList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },
  bannerList: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAdmin, bannerList } = ApiConfig;
    const url = baseAdmin + bannerList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  notificationList: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAdmin, notificationList } = ApiConfig;
    const url = baseAdmin + notificationList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  addSupportDetails: async (formData) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAdmin, addSupportDetails } = ApiConfig;
    const url = baseAdmin + addSupportDetails;
    const headers = {
      'Content-Type': 'multipart/form-data',
      "Authorization": token,
    };
    return ApiCallPost(url, formData, headers);
  },

  getPairs: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseCoin, getPairs } = ApiConfig;
    const url = baseCoin + getPairs;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  coinDetails: async (currency_id) => {
    if (!currency_id) {
      return
    }
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseExchange, coinDetails } = ApiConfig;
    const url = baseExchange + coinDetails;
    const params = {
      "currency_id": currency_id
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  swapToken: async (base_currency, quote_currency, side, amount, swapped_amount) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseSwap, quick_buy_sell } = ApiConfig;
    const url = baseSwap + quick_buy_sell;
    const params = {
      base_currency, quote_currency, side, amount, swapped_amount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  quickBuySellHistory: async (skip, limit) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseSwap, quickBuySellHistory } = ApiConfig;
    const url = baseSwap + quickBuySellHistory + `?skip=${skip}&limit=${limit}`;
    const headers = { 'Content-Type': 'application/json', "Authorization": token };
    return ApiCallGet(url, headers);
  },

  favoriteCoin: async (pairId) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAuth, favoriteCoin } = ApiConfig;
    const url = baseAuth + favoriteCoin;
    const params = {
      "pair_id": pairId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);

  },
  favoriteList: async () => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAuth, favoriteList } = ApiConfig;
    const url = baseAuth + favoriteList;

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);

  },
  setCurrency: async (currencyType) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, setCurrency } = ApiConfig;

    const url = baseAuth + setCurrency;
    const params = {
      currency: currencyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  coinListing: async (formdata) => {
    console.log("🚀 ~ coinListing: ~ formdata:", formdata)
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, coin_listing } = ApiConfig;
    const url = baseAuth + coin_listing;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    };

    return ApiCallPost(url, formdata, headers);
  },

  // P2P Functions

  p2pBuyOrder: async (short_name) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, buyOrder } = ApiConfig;
    const url = baseP2p + buyOrder;
    const params = { short_name };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  fetchPaymentMethods: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, fetchPaymentMethods } = ApiConfig;
    const url = baseP2p + fetchPaymentMethods;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  p2pSellOrder: async (short_name) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, sellOrder } = ApiConfig;
    const url = baseP2p + sellOrder;
    const params = { short_name };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  p2pCoinList: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseCoin, p2pCoinList } = ApiConfig;
    const url = baseCoin + p2pCoinList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  fiatCurrencyList: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, fiatCurrencyList } = ApiConfig;
    const url = baseP2p + fiatCurrencyList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  buyCurrency: async (payableAmount, receivableAmount, id, postAdId, timestamp, sellerSelectedPayment, method) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, buyCurrency } = ApiConfig;
    const url = baseP2p + buyCurrency;
    const params = {
      "amount": payableAmount,
      "receiving_amount": receivableAmount,
      "postAd_user": postAdId,
      "add_id": id,
      "payment_timestamp": timestamp,
      "payment_type": sellerSelectedPayment,
      "payment_method": method,
    }
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  myOrders: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, myOrders } = ApiConfig;
    const url = baseP2p + myOrders;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  runtime: async (status, orderId) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, p2pRuntime } = ApiConfig;
    const url = baseP2p + p2pRuntime;
    const params = {
      status: status,
      order_id: orderId
    };
    const headers = {
      "Authorization": token,
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  rateOrder: async (rating) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, rateOrder } = ApiConfig;

    const url = baseP2p + rateOrder;
    const params = {
      ratings: rating,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  myAds: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, myAds } = ApiConfig;
    const url = baseP2p + myAds;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  currentPrice: async (fiat, asset) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, currentPrice } = ApiConfig;
    const url = baseP2p + currentPrice;
    const params = {
      "base_currency": asset,
      "quote_currency": fiat
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  getCoinList: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseCoin, getCoinList } = ApiConfig;
    const url = baseCoin + getCoinList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  createNewPost: async (input, paymentMethod) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, createNewPost } = ApiConfig;
    const url = baseP2p + createNewPost;
    const params = {
      "base_currency": input?.asset,
      "quote_currency": input?.fiat,
      "side": input?.side,
      "price_type": input?.priceType,
      "fixed_price": input?.price,
      "payment_time": input?.time,
      // "min_amount": input?.minAmount,
      // "max_amount": input?.maxAmount,
      "payment_method": paymentMethod,
      "kyc": input?.kyc,
      "totalTransactions": input?.transaction,
      "counterCurrency": input?.minCurrency,
      "currencyAmount": input?.currencyAmount || 0,
      "volume": input?.volume,
      "registered_days": +input?.regDays
    }
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  submitTicket: async (formData) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseSupport, submitTicket } = ApiConfig;
    const url = baseSupport + submitTicket;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };

    return ApiCallPost(url, formData, headers);
  },

  getUserTickets: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseSupport, getUserTickets } = ApiConfig;
    const url = baseSupport + getUserTickets;
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };

    return ApiCallGet(url, headers);
  },

  replyTicket: async (messagerply, id) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseSupport, replyTicket } = ApiConfig;
    const url = baseSupport + replyTicket;
    const params = {
      replyBy: 1,
      query: messagerply,
      ticket_id: id,

    }
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  addBankDetails: async (account_type, bank_name, account_holder_name, account_number, ifsc_code, branch_name) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, addBankDetails } = ApiConfig;
    const url = baseAuth + addBankDetails;
    const params = { account_type, bank_name, account_holder_name, account_number, ifsc_code, branch_name }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  addUpiDetails: async (formData) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, addUpiDetails } = ApiConfig;
    const url = baseAuth + addUpiDetails;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },


  editBankDetails: async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress, id) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, editBankDetails } = ApiConfig;
    const url = baseAuth + editBankDetails;
    const params = {
      _id: id,
      account_type: accountType,
      bank_name: bankName,
      account_holder_name: holderName,
      account_number: accountNumber,
      ifsc_code: ifscCode,
      branch_name: branchAddress,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  editUpiDetails: async (formData) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseAuth, editUpiDetails } = ApiConfig;
    const url = baseAuth + editUpiDetails;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },


  buyRequest: async (orderId) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, buyRequest } = ApiConfig;
    const url = baseP2p + buyRequest;
    const params = {
      "order_id": orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  addOrderNotification: async (orderId) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, addOrderNotification } = ApiConfig;
    const url = baseP2p + addOrderNotification;

    const params = {
      order_id: orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  notify: async (orderId) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, notify } = ApiConfig;
    const url = baseP2p + notify;
    const params = {
      "order_id": orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  transferToSpot: async (selectedCurrency, withdrawAmount) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, transferFunds } = ApiConfig;
    const url = baseP2p + transferFunds;
    const params = {
      spot_wallet: true,
      amount: +withdrawAmount,
      short_name: selectedCurrency
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  transferToP2P: async (selectedCurrency, withdrawAmount) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, transferFunds } = ApiConfig;
    const url = baseP2p + transferFunds;
    const params = {
      funding_wallet: true,
      amount: +withdrawAmount,
      short_name: selectedCurrency
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  request_refund: async (order_id) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, request_refund } = ApiConfig;
    const url = baseP2p + request_refund;
    const params = { order_id }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  userP2pWallet: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseWallet, userP2pWallet } = ApiConfig;
    const url = baseWallet + userP2pWallet;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  swapHistory: async () => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, swapHistory } = ApiConfig;
    const url = baseP2p + swapHistory;
    const params = {
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },

  notifySellerMerchent: async (status, orderId) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, notifySeller } = ApiConfig;
    const url = baseP2p + notifySeller;
    const params = {
      order_id: orderId,
      merchant_status: status,
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  notifySellerTrader: async (status, orderId) => {
    const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
    const { baseP2p, notifyMerchent } = ApiConfig;
    const url = baseP2p + notifyMerchent;
    const params = {
      order_id: orderId,
      trader_status: status,
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },



};

export default AuthService;
