import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import Header from "./Header";
import { alertErrorMessage, alertSuccessMessage } from "../../Utils/CustomAlertMessage";
import { $ } from "react-jquery-plugin";

const MyOrders = () => {
  const Id = sessionStorage.getItem("userId")
  const navigate = useNavigate();
  const [ordersList, setOrdersList] = useState([]);
  const [rating, setRating] = useState(0);

  const userId = sessionStorage.getItem("userId")

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const myOrders = async () => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.myOrders().then(async (result) => {
      if (result?.success) {
        LoaderHelper?.loaderStatus(false);
        let postedOrder = result?.data?.PostedOrders ? result?.data?.PostedOrders?.map((item) => ({ ...item, orderType: "PostedOrder" })) : []
        let purchedOrder = result?.data?.PurchedOrder ? result?.data?.PurchedOrder?.map((item) => ({ ...item, orderType: "PurchedOrder" })) : []
        let sortedOrder = [...postedOrder, ...purchedOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setOrdersList(sortedOrder);
      }
    });
  };

  const buyP2pPage = (id) => {
    navigate(`/buyPTwop_details/${id}`)
  };

  const [updatedOrders, setUpdatedOrders] = useState([]);
  const calculateReverseTime = (item) => {
    const timestampInSeconds = parseInt(item.payment_timestamp, 10);
    const paymentTimeInMinutes = parseInt(item.payment_time, 10);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const elapsedTimeInSeconds = currentTimeInSeconds - timestampInSeconds;
    const remainingTimeInSeconds = Math.max(paymentTimeInMinutes * 60 - elapsedTimeInSeconds, 0);
    const minutes = Math.floor(remainingTimeInSeconds / 60);
    const seconds = remainingTimeInSeconds % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return { formattedMinutes, formattedSeconds };
  };

  const updateReverseTime = () => {
    const updatedOrdersList = ordersList.map((item) => {
      const { formattedMinutes, formattedSeconds } = calculateReverseTime(item);
      return { ...item, reverseTime: { formattedMinutes, formattedSeconds } };
    });
    setUpdatedOrders(updatedOrdersList?.reverse());
  };

  const notifySellerMerchent = async (orderDetails, status, orderId) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.notifySellerMerchent(status, orderId)
      if (result?.success) {
        if (status == "CONFIRMED" && orderDetails?.postAd_user == Id && orderDetails?.side == "SELL") {
          notifyHandler(orderId)
          addOrderNotification(orderId)
        }
        else {
          if (status == 'DISPUTE' || orderDetails?.trader_id == Id || orderDetails?.postAd_user == Id) {
          }
        }
      }
    } finally { LoaderHelper.loaderStatus(false); }
  };

  const addOrderNotification = async (orderId) => {
    try {
      LoaderHelper.loaderStatus(true);
      await AuthService.addOrderNotification(orderId).then(async (result) => {
        if (result.success) {
          try {
            await myOrders()
            alertSuccessMessage("Notification sent to trader")
          } catch (error) {
            console.log(error);
          }
        }
      });
    } finally { LoaderHelper.loaderStatus(false); }
  };

  const notifySellerTrader = async (orderDetails, status, orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notifySellerTrader(status, orderId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        if (status == "CONFIRMED" && orderDetails?.trader_id == Id && orderDetails?.side == "BUY") {
          notifyHandler(orderId)
          addOrderNotification(orderId)
        }
        else {
          if (status == 'DISPUTE' || orderDetails?.trader_id == Id || orderDetails?.postAd_user == Id) {
          }
        }
      }
    });
  };

  const notifyHandler = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notify(orderId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage("Funds transfered successfully")
      }
    });
  };

  const handleRunTime = async (status, orderId, orderDetails) => {
    LoaderHelper.loaderStatus(true);
    const result = await AuthService.runtime(status, orderId)
    if (result.success) {
      LoaderHelper.loaderStatus(false);
      if (status == "DISPUTE") {
        alertErrorMessage("Your order is in dispute, please raise a ticket.")
        navigate("/SupportPage")
      } else {
        $("#rating_modal").modal('show')
        if (orderDetails?.trader_id == Id) {
          notifySellerTrader(orderDetails, "CONFIRMED", orderId)
        } else {
          notifySellerMerchent(orderDetails, "CONFIRMED", orderId)
        }
      }
    } else {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(result.message)
    }
  };

  const rateHandler = async (rating) => {
    const result = await AuthService.rateOrder(rating)
    if (result.success) {
      alertSuccessMessage(result.message)
      $("#rating_modal").modal("hide")
      myOrders()
    } else {
      alertErrorMessage(result.message)
    }
  };

  const requestRefund = async (order_id) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.request_refund(order_id)
      if (result?.success) {
        alertSuccessMessage(result?.message)
        myOrders()
      } else {
        alertErrorMessage(result?.message)
      }
    } catch (error) {
      alertErrorMessage(error.message)
    } finally { LoaderHelper.loaderStatus(false) }
  }

  const Statement = updatedOrders.filter(item => item?.status == "CONFIRMED")

  useEffect(() => {
    updateReverseTime();
    const intervalId = setInterval(updateReverseTime, 1000);
    return () => clearInterval(intervalId);
  }, [ordersList]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    myOrders()
  }, []);


  return (
    <>
      <Header />

      <div className="pp_filter_bar" >
        <div className="container" >
          <div className="card-filter" >
            <div className="card-body-top curve" >
              <div className="body_left body_right_w_filter" >
                <ul className="nav nav-pills  mrt_row" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link  subtabs_link    active" id="pills-Orders-tab" data-bs-toggle="pill" data-bs-target="#pills-Orders"
                      type="button" role="tab" aria-controls="pills-Orders" aria-selected="true">P2P Orders</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link subtabs_link " id="pills-Statement-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-Statement" type="button" role="tab" aria-controls="pills-Statement" aria-selected="false">Statement</button>
                  </li>
                </ul>
                <div className="mob_filter d-md-none shadow-soft" >
                  <i className="ri-filter-2-fill"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pp_data cst-table ant-table" >
        <div className="container" >

          <div className="tab-content" id="myTabContent">

            {/* buy data tab */}
            <div className="tab-pane fade show active" id="pills-Orders" role="tabpanel" aria-labelledby="ills-Orders-tab-tab">
              <div className="cst-table-content shadow-soft table-responsive ">
                <table className="table " >
                  <thead className="cst-table-thead">
                    <tr>
                      <th className=" ">Type</th>
                      <th className=" ">Price</th>
                      <th className=" ">Crypto Amount</th>
                      <th className=" ">Payment Method</th>
                      <th className=" ">Status </th>
                      <th className=" ">Action </th>
                    </tr>
                  </thead>
                  {updatedOrders?.length > 0 ? updatedOrders?.map((item) => {
                    // disable dispute button after 10 minutes of order cancelled
                    const timestamp = item?.payment_timestamp;
                    const timeLimit = +item?.payment_time
                    const date = new Date(timestamp * 1000);
                    date.setMinutes(date.getMinutes() + timeLimit + 10);
                    const currentTime = new Date();

                    const fontColor = (item?.orderType === "PostedOrder" && item?.side === 'BUY') ? "success" : (item?.orderType === "PostedOrder" && item?.side === 'SELL') ? "danger" : item?.side === 'BUY' ? 'danger' : 'success'

                    return (
                      <tbody className={`cst-table-tbody active_order ${item?.status === "IN PROGRESS" && " cursor-pointer"}`} key={item?._id} onClick={() => { if (item?.status === "IN PROGRESS") { buyP2pPage(item?.order_id) } }}>
                        <tr className=" ">
                          <td className=" ">
                            <div className="advertiseList_avatarContent__uAQHP">
                              <div className="advertiseList_adAvatarRight__SbOZP"><span className={`advertiseList_nickName__VI9tJ text-${fontColor}`}>  {item?.orderType === "PostedOrder" ? item?.side : item?.side === 'BUY' ? 'SELL' : 'BUY'}</span>
                                <p className="advertiseList_merchantOrder__aO2o_"> Date:  <Moment format="DD:MM:YY hh:mm A">{item?.createdAt}</Moment> </p>
                                <div className="advertiseList_firstTrade__Z5OC5">
                                  <p className="advertiseList_merchantOrder__aO2o_">
                                    <span className=" " >
                                      {item?.order_id}
                                    </span>
                                    <span className="advertiseList_tradingLine__swVrO"></span>
                                    <span className=" ">
                                      <button type="button" className="btn custom-btn custom-border-btn  custom-border-btn-white btn-sm px-1" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                                        .writeText(item?.order_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                                        <i className="ri-file-copy-line text-gradient"></i>
                                      </button>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className=" ">
                            {item?.fixed_price} {item?.quote_short_name} / {item?.base_short_name}
                          </td>
                          <td className=" ">
                            {item?.amount} {item?.base_short_name}
                          </td>
                          <td className=" ">
                            {item?.payment_method[0]?.type}
                          </td>
                          <td className="  text-danger">
                            {(item?.status === "CANCELLED" && item?.merchant_status === "PENDING" && item?.trader_status === "PENDING" && item?.refundedToSeller === true && userId === item?.trader_id && item?.side === "BUY") ? "REFUNDED" : item?.status}
                          </td>
                          <td className="text-danger">
                            {currentTime < date && item?.refundedToSeller === false ?
                              <>
                                <button type="button" className="btn btn-gradient btn-sm" disabled={item?.status === "DISPUTE" || item?.status === "CONFIRMED" || item?.status === "SETTLED"} onClick={() => handleRunTime("CONFIRMED", item?.order_id, item)}>{(item?.side == "SELL" && item?.postAd_user == userId) ? "I have recieved the payment " : (item?.side === "SELL" && item?.trader_id == userId) ? "I have recieved the funds " : (item?.side === "BUY" && item?.trader_id == userId) ? "I have recieved the payment" : (item?.side === "BUY" && item?.postAd_user == userId) ? "I have recieved the funds " : null}</button>

                                <button type="button" className="btn btn-danger btn-sm mx-1 fw-normal" disabled={item?.status === "DISPUTE" || item?.status === "CONFIRMED" || item?.status === "SETTLED"} onClick={() => handleRunTime("DISPUTE", item?.order_id, item)}>Raise Dispute</button>

                              </>
                              :
                              <>
                                <button type="button" className="btn btn-gradient btn-sm" disabled >{(item?.side == "SELL" && item?.postAd_user == userId) ? "I have recieved the payment " : (item?.side === "SELL" && item?.trader_id == userId) ? "I have recieved the funds " : (item?.side === "BUY" && item?.trader_id == userId) ? "I have recieved the payment" : (item?.side === "BUY" && item?.postAd_user == userId) ? "I have recieved the funds " : null}</button>

                                <button type="button" disabled className="btn btn-danger btn-sm mx-1 fw-normal" aria-disabled>Raise Dispute</button>
                              </>
                            }

                            {(item?.status === "CANCELLED" && item?.merchant_status === "PENDING" && item?.trader_status === "PENDING" && item?.refundedToSeller === false && userId === item?.trader_id && item?.side === "BUY") &&
                              < button type="button" className="btn btn-primary btn-sm mx-1 fw-normal" onClick={() => requestRefund(item?.order_id)}>Request Refund</button>
                            }

                            {item?.status === "DISPUTE" &&
                              <Link type="button" className="btn btn-primary btn-sm mx-1 fw-normal" to="/SupportPage">Support</Link>}


                          </td>
                        </tr>
                      </tbody>
                    )
                  }) :
                    <tbody className="cst-table-tbody active_order" >
                      <tr rowSpan="6" colSpan="6"  >
                        <td className="" rowSpan="6" colSpan="6" >
                          <p className="text-center pb-4 no-data h-100 mb-0" >
                            <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                            No Data Available
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  }

                </table>
              </div>
            </div>

            {/* sell data tab */}
            <div className="tab-pane fade" id="pills-Statement" role="tabpanel" aria-labelledby="pills-Statement-tab">
              <div className="cst-table-content shadow-soft table-responsive ">
                <table className="table " >
                  <thead className="cst-table-thead">
                    <tr>
                      <th className=" ">Type</th>
                      <th className=" ">Price</th>
                      <th className=" ">Crypto Amount</th>
                      <th className=" ">Payment Method</th>
                      <th className=" ">Status </th>
                    </tr>
                  </thead>
                  {Statement?.length > 0 ? Statement?.map((item) => {
                    const fontColor = (item?.orderType === "PostedOrder" && item?.side === 'BUY') ? "success" : (item?.orderType === "PostedOrder" && item?.side === 'SELL') ? "danger" : item?.side === 'BUY' ? 'danger' : 'success'
                    return (
                      <tbody className="cst-table-tbody active_order" key={item?._id} >
                        <tr className=" ">
                          <td className=" ">
                            <div className="advertiseList_avatarContent__uAQHP">
                              <div className="advertiseList_adAvatarRight__SbOZP"><span className={`advertiseList_nickName__VI9tJ text-${fontColor}`}> {item?.orderType === "PostedOrder" ? item?.side : item?.side === 'BUY' ? 'SELL' : 'BUY'}</span>
                                <p className="advertiseList_merchantOrder__aO2o_"> Date:  <Moment format="DD:MM:YY hh:mm A">{item?.createdAt}</Moment> </p>
                                <div className="advertiseList_firstTrade__Z5OC5">
                                  <p className="advertiseList_merchantOrder__aO2o_">
                                    <span className=" " >
                                      {item?.order_id}
                                    </span>
                                    <span className="advertiseList_tradingLine__swVrO"></span>
                                    <span className=" "><button type="button" className="btn custom-btn custom-border-btn  custom-border-btn-white btn-sm px-1 " style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                                      .writeText(item?.order_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                                      <i className="ri-file-copy-line text-gradient"></i>
                                    </button></span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className=" ">
                            {item?.fixed_price} {item?.quote_short_name} / {item?.base_short_name}
                          </td>
                          <td className=" ">
                            {item?.amount} {item?.base_short_name}
                          </td>
                          <td className=" ">
                            {item?.payment_method[0]?.type}
                          </td>
                          <td className="  text-danger">
                            {item?.status}
                          </td>
                        </tr>
                      </tbody>
                    )
                  }) :
                    <tbody className="cst-table-tbody active_order" >
                      <tr rowSpan="6" colSpan="6"  >
                        <td className="" rowSpan="6" colSpan="6" >
                          <p className="text-center pb-4 no-data h-100 mb-0" >
                            <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                            No Data Available
                          </p>
                        </td>
                      </tr>
                    </tbody>}

                </table>
              </div>

            </div>
          </div>

        </div>
      </div>

      {/* Trading Requirement Modal */}
      <div className="modal fade modal_requirements" id="t_requirements" tabIndex="-1" role="dialog" aria-labelledby="t_requirementsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column ">
              <h3 className="modal-title" id="placeBitLaebl"> Trading Requirement </h3>
            </div>
            <div className="modal-body p-0">
              <div className="css-ag510y">
                <div className="css-joa6mv">
                  <div className="css-1c6ljq6">
                    <div className="css-vurnku">Complete KYC Verification</div>
                    <div className="css-vurnku">
                      <button data-bn-type="button" className="btn btn-sm btn-warning css-stohmr ">Verify</button>
                    </div>
                  </div>
                  <div className="css-1c6ljq6">
                    <div className="css-vurnku">Enable SMS authentication</div>
                    <div className="css-vurnku">
                      <div className="css-9fayl6">Done
                        <i className="ri-checkbox-circle-fill ms-1"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="css-1bwopy1 row gx-1">
                  <div className="col-6 col-md-4" >
                    <button type="button" className="btn   shadow-soft btn-block w-100" data-bs-dismiss="modal"><span>Cancel</span></button>
                  </div>
                  <div className="col-6  col-md-8" >
                    <a href="/buyPTwop_details" className="btn btn-gradient btn-block w-100  "  > <span>Get Verified</span> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Rating Modal */}
      <div className="modal fade modal_requirements" id="rating_modal" tabIndex="-1" role="dialog" aria-labelledby="rating_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
          <div className="modal-content p-2 pb-5" style={{ display: "flex", alignItems: "center" }}>
            <div className="modal-header flex-column px-8" style={{ borderBottom: "none" }}>
              <h3 className="modal-title" id="placeBitLaebl">Please Rate the order experience</h3>
            </div>
            <div>
              {[1, 2, 3, 4, 5].map((star) => (
                <span key={star} style={{ cursor: 'pointer', color: star <= rating ? 'gold' : 'gray' }} onClick={() => handleRatingChange(star)}>★</span>
              ))}
            </div>

            <p>You rated: {rating}</p>
            <button type="button" className="btn btn-gradient" data-bs-dismiss="modal" aria-label="Close" onClick={() => rateHandler(rating)}>Submit</button>
          </div>
        </div>
      </div>

    </>
  );
};

export default MyOrders;
