import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import { alertSuccessMessage, alertWarningMessage } from "../../Utils/CustomAlertMessage";
import { CountryDetails } from "../../Utils/CountryList";

const ForgotPassPage = () => {
  const [signId, setSignId] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordError2, setPasswordError2] = useState(null);
  const [passwordType, setpasswordType] = useState(true);
  const [passwordType2, setpasswordType2] = useState(true);

  const handleForgot = async (signId, otp, password, cPassword) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.forgotPassword(signId, otp, password, cPassword).then(async (result) => {
      if (result?.success) {
        alertSuccessMessage(result.authRes);
        navigate("/login");
      }
    }
    );
  };

  const handleGetCode = async (signId) => {

    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, 'forgot').then(async (result) => {
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setIsShow(true);
      }
    });
  };
  const validatePassword = (e) => {
    setPassword(e.target.value);
    const validationCharac = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/);
    if (e.target.value.match(validationCharac)) {
      setPasswordError(false)
    } else {
      setPasswordError(true)
    }
  };


  return (
    <>
      <section className="inner-page-banner pb-0">
      </section>
      <section className="">
        <div className="container">
          <div className="row align-items-center  justify-content-center">
            <div className="col-xl-7 col-lg-12 ">
              <div className="poster_slider">
                <h2 className="mb-5  ">
                  Welcome to <br />{" "}
                  <span className="text-gradient">WCCEXC</span>{" "}
                </h2>
                <Swiper
                  className="market_slider  pb-11"
                  spaceBetween={10}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                  }}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                  }}
                >
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_1.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_2.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_3.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_1.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_2.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_3.png" />
                    </a>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-xl-5 col-lg-12 ">
              <div className="signup-wrapper">
                <div className="signup-content signin-content">
                  <div className={`sign-in_tab ${isShow && "d-none"} `} id="qwert">
                    <div className="mb-3">
                      <h3 className="mb-2  ">Forgot Password</h3>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane show px-0 container active"
                        id="login_Mobile"
                      >
                        <form action="#">
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="Code" className="form-label">
                                Email
                                </label>
                              </div>
                            </div>
                            <div className="col-12 mb-3">
                              <div className="field-box">
                                <input
                                  id="mobile"
                                  type="text"
                                  placeholder="Enter Email"
                                  value={signId}
                                  onWheel={(e) => { e.target.blur() }}
                                  onChange={(e) => setSignId(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 mt-3">
                              <div className="field-box">
                                <button
                                  className="btn btn-gradient w-100 justify-content-center btn-medium "
                                  type="button"
                                  onClick={() => handleGetCode(signId)}
                                >
                                  <span>Next</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-12">
                        Back to{" "}
                        <Link to="/login" className="color-primary">
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={`new_password_tab ${!isShow && "d-none"}`}>
                    <div className="mb-3">
                      <h3 className="mb-4">Reset password</h3>
                      <p className="medium">Your Code will be sent to - {signId}</p>
                    </div>
                    <form action="#">
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="Code" className="form-label">
                              Enter Code
                            </label>
                            <input
                              id="Verification"
                              type="number"
                              placeholder=""
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              onWheel={(e) => { e.target.blur() }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="Code" className="form-label">
                              New Password
                            </label>
                            <div className="field-otp-box">
                              <input
                                id="new_Password"
                                type={passwordType ? "password" : 'text'}
                                placeholder=""
                                value={password}
                                onChange={(e) => validatePassword(e)}
                              />
                              <a href="#/" className="show_password opt_btn btn-sm" onClick={() => { setpasswordType(!passwordType) }}>
                                {passwordType ? <i className="ri-eye-off-line" />
                                  : <i className="ri-eye-line" />}
                              </a>
                            </div>
                            {passwordError && <span style={{ color: "red", fontWeight: "300" }}>Password must be at least 8 characters including characters, number and special character.</span>}

                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="Code" className="form-label">
                              Confirm Password
                            </label>
                            <div className="field-otp-box">
                              <input
                                id="confirm_new_Password"
                                type={passwordType2 ? "password" : 'text'}
                                placeholder=""
                                value={cPassword}
                                onChange={(e) => { setCpassword(e.target.value); { setPasswordError2(e.target.value === password ? false : true) } }}
                              />
                              <a href="#/" className="show_password opt_btn btn-sm" onClick={() => { setpasswordType2(!passwordType2) }}>
                                {passwordType2 ? <i className="ri-eye-off-line" />
                                  : <i className="ri-eye-line" />}
                              </a>
                            </div>
                            {passwordError2 && <span style={{ color: "red", fontWeight: "300" }}>Password does not macth.</span>}
                          </div>
                        </div>
                        <div className="col-md-12 mb-4 mt-4">
                          <div className="field-box">
                            <button disabled={passwordError || passwordError === null || passwordError2 || passwordError2 === null}
                              className="btn btn-gradient w-100 justify-content-center btn-medium "
                              type="button"
                              onClick={() => handleForgot(signId, otp, password, cPassword)}
                            >
                              <span> Confirm </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-12">
                        Back to{"  "}
                        <Link to="/login" className="color-primary">
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default ForgotPassPage;
