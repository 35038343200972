import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFriends, ImageMessageSend } from './store/actions/messengerAction';
import { io } from 'socket.io-client';
import useSound from 'use-sound';
import notificationSound from './audio/notification.mp3';
import sendingSound from './audio/sending.mp3';
import Message from './Message';
import MessageSend from './MessageSend';
import { ProfileContext } from '../../Context';
import { ApiConfig } from '../../Api/Api_Config/ApiEndpoints';
import { alertSuccessMessage } from '../CustomAlertMessage';
import { SocketContext } from '../SocketContext';

const Messenger = ({ frndId, orderId, orderStatus }) => {
     const userId = sessionStorage.getItem("userId")

     const [notificationSPlay] = useSound(notificationSound);
     const [sendingSPlay] = useSound(sendingSound);
     const dispatch = useDispatch();
     const scrollRef = useRef(null);

     const [message, setMessage] = useState([]);
     const [currentfriend, setCurrentFriend] = useState('');
     const [newMessage, setNewMessage] = useState('');

     const { friends } = useSelector(state => state.messenger);
     const { userDetails } = useContext(ProfileContext);
     const { firstName, lastName } = userDetails
     const { socket } = useContext(SocketContext);

     const scrollToBottom = () => {
          if (scrollRef.current) {
               scrollRef.current.scroll({
                    top: scrollRef.current.scrollHeight,
                    behavior: 'smooth',
               });
          }
     };



     useEffect(() => {
          if (socket && Object.keys(friends).length > 0) {
               let payload = {
                    'message': 'chat',
                    userId: userId,
                    frnId: friends?.data[0]?.friendInfo[0]?._id,
                    order_id: orderId,
               }
               socket.emit('message', payload);

               let validateNewMessage = []
               socket.on('new-message', (messages) => {
                    setMessage(messages)
                    if (validateNewMessage?.length < messages?.length) {
                         if (messages[messages?.length - 1]?.senderId !== userId && validateNewMessage?.length !== 0) {
                              notificationSPlay();
                              alertSuccessMessage(`New Message`)
                         }

                         // scrollToBottom();

                    }
                    validateNewMessage = messages

               })
          }

          // return () => {
          //      socket.close()
          // }

     }, [socket, friends])

     useEffect(() => {
          if (Object.keys(friends).length > 0) {
               if (friends?.data?.length > 0) {
                    setCurrentFriend(friends?.data[0]?.friendInfo[0])
               }
          }
     }
          , [friends])

     useEffect(() => {
          scrollToBottom();

     }, [message]);


     const inputHendle = (e) => {
          setNewMessage(e.target.value);
     }

     const sendMessage = (e) => {
          e.preventDefault();
          sendingSPlay();
          const data = {
               senderName: firstName == null ? " " : firstName + " " + lastName,
               recieverId: currentfriend._id,
               order_id: orderId,
               message: newMessage ? newMessage : '❤',
               userId,
               type: "send-message"
          }
          socket.emit('message', data);
          setNewMessage('')
          let payload = {
               'message': 'chat',
               userId: userId,
               frnId: friends?.data[0]?.friendInfo[0]?._id,
               order_id: orderId,
          }
          socket.emit('message', payload);

          scrollToBottom();
     }


     useEffect(() => {
          dispatch(getFriends(frndId, orderId));
     }, [frndId]);



     const emojiSend = (emu) => {
          setNewMessage(`${newMessage}` + emu);
     }

     const ImageSend = (e) => {
          if (e.target.files.length !== 0) {
               sendingSPlay();
               const imagename = e.target.files[0].name;
               const formData = new FormData();
               formData.append('senderName', firstName + " " + lastName);
               formData.append('recieverId', currentfriend._id);
               formData.append('chat_image', e.target.files[0]);
               formData.append('image', imagename);
               formData.append('order_id', orderId)
               dispatch(ImageMessageSend(formData));

               const data = {
                    senderName: firstName == null ? " " : firstName + " " + lastName,
                    recieverId: currentfriend._id,
                    order_id: orderId,
                    message: "",
                    userId,
                    type: "send-message"
               }
               socket.emit('message', data);
          }
     }

     return (
          <>

               {currentfriend &&
                    <div className="chat_box card">
                         <div className="chat-header">
                              <div className="avatar-box  d-flex align-items-start justify-content-">
                                   <div><span className="avatar shadow-soft "> <span><img className='avatar ' width="50px" src={currentfriend?.profilepicture ? ApiConfig.baseUrl + currentfriend?.profilepicture : "/images/user.png"} alt='' /></span></span>
                                        <div>
                                             <span className="nickName d-block">{currentfriend?.firstName || "User"}</span>
                                             <p className="advertiseList_merchantOrder__aO2o_ ms-2">
                                                  <span className="text-warning">
                                                       {currentfriend?.userRatings > 0 ? (
                                                            Array.from({ length: 5 }, (_, index) => (
                                                                 <i
                                                                      key={index}
                                                                      className={` me-1 ri-star-${index < currentfriend.userRatings ? 'fill' : 'line'}`}
                                                                 ></i>
                                                            ))
                                                       ) : (
                                                            "No Rating Available"
                                                       )}
                                                  </span>
                                                  {currentfriend?.userRatings > 0 && (
                                                       <>
                                                            <span className="advertiseList_tradingLine__swVrO"></span>
                                                            <span className="text-warning">{currentfriend.userRatings}</span>
                                                       </>
                                                  )}
                                             </p>

                                        </div>
                                   </div>
                              </div>
                              {currentfriend?.p2p_trades == 0 ? "" :
                                   <div className="other-box">
                                        <div>
                                             <div className="line-info">
                                                  <div>
                                                       <div className="value">{currentfriend?.p2p_trades} successful trades</div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>}
                         </div>
                         <Message
                              message={message}
                              currentfriend={currentfriend}
                              scrollRef={scrollRef}
                         />

                         {orderStatus == "CONFIRMED" ? "" :
                              <MessageSend
                                   inputHendle={inputHendle}
                                   newMessage={newMessage}
                                   sendMessage={sendMessage}
                                   emojiSend={emojiSend}
                                   ImageSend={ImageSend}
                                   orderStatus={orderStatus}
                              />
                         }
                    </div>

               }
          </>

     )
};

export default Messenger;
