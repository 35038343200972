import axios from 'axios';
import { FRIEND_GET_SUCCESS, MESSAGE_SEND_SUCCESS } from "../types/messengerType";
import { ApiConfig } from '../../../../Api/Api_Config/ApiEndpoints';

export const getFriends = (frndId, orderId) => async (dispatch) => {
     try {
          const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
          const config = {
               headers: {
                    Authorization: token,
               },
          };

          const payload = {
               friendId: frndId,
               order_id: orderId
          };


          const response = await axios.post(`${ApiConfig.baseUrl}v1/messenger/get-friends`, payload, config);
          dispatch({
               type: FRIEND_GET_SUCCESS,
               payload: {
                    friends: response.data
               }
          })

     } catch (error) {
          console.log(error.response.data);
     }

}


export const ImageMessageSend = (formData) => async (dispatch) => {
     try {
          const token = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
          const config = {
               headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": token,
               },
          };
          const response = await axios.post(`${ApiConfig.baseUrl}v1/messenger/image_message_send`, formData, config);
          dispatch({
               type: MESSAGE_SEND_SUCCESS,
               payload: {
                    message: response?.data.data
               }
          })

     } catch (error) {
          console.log(error.response.data);

     }

}
