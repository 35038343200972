import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import { $ } from "react-jquery-plugin";
import { alertErrorMessage, alertSuccessMessage } from "../../Utils/CustomAlertMessage";
import Header from "./Header";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import useCountdownTimer from "../../Utils/CountdownP2P/CountdownTimer";
import { ApiConfig } from "../../Api/Api_Config/ApiEndpoints";
import Messenger from "../../Utils/Chat/Messenger";
// import Messenger from "../../../utils/Chat/Messenger";

const BuyPTwop = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [selectedPayment, setSelectedPayment] = useState();
  const [timerData, setTimerData] = useState({ timeStamp: '', timeLimit: '' });
  const [isComponentMounted, setComponentMounted] = useState(false);
  const [frndId, setFrndId] = useState('')
  const navigate = useNavigate()
  const [rating, setRating] = useState(0);

  const Id = sessionStorage.getItem("userId")

  const { remainingTime, formattedTime } = useCountdownTimer(timerData?.timeStamp, timerData?.timeLimit);
  let timer = formattedTime?.split("");

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const orderDetail = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.buyRequest(orderId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setOrderDetails(result?.data?.OrderData)
        setSelectedPayment(result?.data?.PostData?.payment_method[0])
        setTimerData({
          timeStamp: +result?.data?.OrderData?.payment_timestamp,
          timeLimit: +result?.data?.OrderData?.payment_time * 60,
        })
      }
    });
  };

  const rateHandler = async (rating) => {
    const result = await AuthService.rateOrder(rating)
    if (result.success) {
      alertSuccessMessage(result.message)
      navigate("/p2p_trading")
    } else {
      alertErrorMessage(result.message)
    }
  };

  const addOrderNotification = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.addOrderNotification(orderId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage("Notification sent!!")
          orderDetail();
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const notifyHandler = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notify(orderId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage("Funds transfered successfully")
      }
    });
  };

  const notifySellerMerchent = async (status, orderId) => {
    LoaderHelper.loaderStatus(true);
    const result = await AuthService.notifySellerMerchent(status, orderId)
    LoaderHelper.loaderStatus(false);
    if (result?.success) {
      if (status === "CONFIRMED") {
        notifyHandler()
        addOrderNotification(orderId)
      }
      else if (status === 'DISPUTE') {
        handleRunTime("DISPUTE", orderId)
      }
    }
  };

  const notifySellerTrader = async (status, orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notifySellerTrader(status, orderId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        if (status === "CONFIRMED") {
          notifyHandler()
          addOrderNotification(orderId)
        }
        else if (status === 'DISPUTE') {
          handleRunTime("DISPUTE", orderId)
        }
      }
    });
  };

  const copyText = (data) => {
    navigator.clipboard.writeText(data).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })
  };

  const handleRunTime = async (orderId, status) => {
    LoaderHelper.loaderStatus(true);
    const result = await AuthService.runtime(orderId, status)
    LoaderHelper.loaderStatus(false);
    if (result.success) {
      if (status == "DISPUTE") {
        alertErrorMessage("Your order is in dispute, please raise a ticket.")
        navigate("/SupportPage")
        return
      }
      alertErrorMessage(' Your order is cancelled...')
      navigate("/p2p_trading")
    } else {
      alertErrorMessage(result.message)
    }
  };

  useEffect(() => {
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (timerData?.timeStamp && timerData?.timeLimit) {
      let maxTimeToComplete = timerData?.timeStamp + timerData?.timeLimit;
      let currTime = Math.floor(Date.now() / 1000);
      if (currTime >= maxTimeToComplete) {
        handleRunTime("CANCELLED", orderId)
      }
    }
  }, [remainingTime, timerData]);


  // useEffect(() => {
  //   if (isComponentMounted && formattedTime == "00:00" && orderDetails.status != "CONFIRMED") {
  //     handleRunTime("PENDING", orderId)
  //   }
  // }, [formattedTime])

  useEffect(() => {
    if (orderDetails) {
      if (orderDetails.postAd_user == Id) {
        setFrndId(orderDetails.trader_id)
      } else {
        setFrndId(orderDetails.postAd_user)
      }
    }

  }, [orderDetails])


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    orderDetail();
  }, []);


  return (
    <>
      <Header />


      <div className="buy_bar" >
        <div className="container" >
          <div className="bar_row" >
            <div className="bar_left" >
              <div className="bar_title" >
                <h3 className="d-flex align-items-center justify-content-between" >
                  {orderDetails?.postAd_user == Id ? orderDetails?.side === 'SELL' ? "Sell " : 'Buy  ' : orderDetails?.side === 'SELL' ? "Buy " : 'Sell  '}
                  {orderDetails?.base_short_name}   <span className="btn btn-sm btn-gradient mobile_chat_icon d-md-none shadow-soft  " > Chat </span> </h3>
                <p className="mb-0" >Order has been created :
                  {orderDetails.status == "CONFIRMED" ? "" :
                    <div className="CountDown_orderCountdown__Gx8IG">
                      <span data-testid="minutes">
                        <span><span className="CountDown_minutesContent___Z3lA shadow-soft">{timer[0] === 'N' ? '0' : timer[0]}</span>
                          <span className="CountDown_minutesContent___Z3lA  shadow-soft">{timer[1] === 'a' ? "0" : timer[1]}</span>
                        </span>
                      </span><span className="CountDown_segmentation__1LinY">:</span>
                      <span data-testid="seconds"><span>
                        <span className="CountDown_minutesContent___Z3lA shadow-soft ">{timer[3] === ':' ? "0" : timer[3]}</span>
                        <span className="CountDown_minutesContent___Z3lA  shadow-soft">{timer[4] === 'N' ? "0" : timer[4]}</span>
                      </span>
                      </span>
                    </div>}<br/>
                    
                </p>
              <small>   (Order will automatically get cancelled if time limit completed. Get refund from my order section if order is cancelled)</small>
              </div>
            </div>
            <div className="bar_right" >
              <p>
                Order Number <b className="ms-3">{orderDetails?.order_id} <i className=" ms-2 ri-file-copy-line cursor-pointer" onClick={() => copyText(orderDetails?.order_id)}></i> </b>
              </p>
              <p>
                Time Created <b className="ms-3"><Moment format="DD:MM:YY hh:mm A" >{orderDetails?.createdAt}</Moment></b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bar_data" >
        <div className="container" >
          <div className="row" >
            <div className="col-lg-7" >
              <div className="buy_data" >
                <div className="timeline" >
                  <div className="timeline_data" >
                    <h5>Confirm Order Details</h5>
                    <div className="row mt-3 mt-md-5" >
                      <p className="col-6  col-md-3" >
                        <span className="  advertiseList_expandPrice__Bgj3_">
                          <b>{orderDetails?.fixed_price}{orderDetails?.quote_short_name} / {orderDetails?.base_short_name}</b> </span>
                        <span className="advertiseList_adLimitTitle__sGUqF d-block">Price </span>
                      </p>

                      <p className="col-6  col-md-3" >
                        <span className="  advertiseList_expandPrice__Bgj3_">
                          <b>{orderDetails?.amount} {orderDetails?.base_short_name}</b> </span>
                        <span className="advertiseList_adLimitTitle__sGUqF d-block">Quantitiy</span>
                      </p>

                      <p className="col-6  col-md-3" >
                        <span className="  advertiseList_expandPrice__Bgj3_">
                          <b>{orderDetails?.amount * orderDetails?.fixed_price} {orderDetails?.quote_short_name} </b> </span>
                        <span className="advertiseList_adLimitTitle__sGUqF d-block">Amount</span>
                      </p>



                    </div>
                  </div>

                  <div className="timeline_data" >
                    <h5> Payment Method </h5>
                    <div className="ac_details" >
                      <div className="row" >
                        <div className="col-md-5" >

                          <div className="pay_radio" >
                            {
                              (orderDetails?.side === 'BUY' || orderDetails?.side === 'SELL') && orderDetails?.trader_id == Id &&

                              <div className="form-check">
                                <label className="form-check-label" for="flexRadioDefault1">
                                  {selectedPayment?.type}
                                </label>
                              </div>}

                            {(orderDetails?.side === 'BUY' || orderDetails?.side === 'SELL') && orderDetails?.postAd_user == Id &&
                              <div className="form-check">
                                <label className="form-check-label" for="flexRadioDefault1">
                                  {orderDetails?.payment_method[0]?.type}
                                </label>
                              </div>
                            }
                          </div>

                        </div>
                        <div className="col-md-7" >
                          <div className="ac_data" >
                            {
                              (orderDetails?.side === 'BUY' && orderDetails?.trader_id == Id) &&
                              selectedPayment && Object.entries(selectedPayment)?.map(([key, value]) => {
                                const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                return (
                                  (updatedName !== 'id' && updatedName !== 'type' && updatedName !== 'user id' && updatedName !== 'createdat' && updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified') &&
                                  <div className="ac_row" key={key}>
                                    {updatedName == 'upi image' ? <a href={ApiConfig.baseUrl + value} target="_blank" rel="noreferrer"> <img alt="payment_method" src={ApiConfig.baseUrl + value} width="100px" height='100px' /></a> :
                                      <>
                                        <small> {updatedName} </small>
                                        <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                  </div>
                                )
                              })}

                            {
                              (orderDetails?.side === 'SELL' && orderDetails?.trader_id == Id) &&
                              selectedPayment && Object.entries(selectedPayment)?.map(([key, value]) => {
                                const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                return (
                                  (updatedName !== 'id' && updatedName !== 'type' && updatedName !== 'user id' && updatedName !== 'createdat' && updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified') &&
                                  <div className="ac_row" key={key}>
                                    {updatedName == 'upi image' ? <a href={ApiConfig.baseUrl + value} target="_blank" rel="noreferrer"> <img alt="payment_method" src={ApiConfig.baseUrl + value} width="100px" height='100px' /> </a> :
                                      <>
                                        <small> {updatedName} </small>
                                        <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                  </div>
                                )
                              })}

                            {
                              (orderDetails?.side === 'BUY' && orderDetails?.postAd_user == Id) &&
                              orderDetails?.payment_method[0] && Object.entries(orderDetails?.payment_method[0])?.map(([key, value]) => {
                                const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                return (
                                  (updatedName !== 'id' && updatedName !== 'user id' && updatedName !== 'createdat' && updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified') &&
                                  <div className="ac_row" key={key}>
                                    {updatedName == 'upi image' ? <a href={ApiConfig.baseUrl + value} target="_blank" rel="noreferrer"> <img alt="payment_method" src={ApiConfig.baseUrl + value} width="100px" height='100px' /> </a> :
                                      <>
                                        <small> {updatedName} </small>
                                        <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                  </div>
                                )
                              })

                            }

                            {
                              (orderDetails?.side === 'SELL' && orderDetails?.postAd_user == Id) &&
                              orderDetails?.payment_method[0] && Object.entries(orderDetails?.payment_method[0])?.map(([key, value]) => {
                                const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                console.log(updatedName, "99")
                                return (
                                  (updatedName !== 'id' && updatedName !== 'user id' && updatedName !== 'createdat' && updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified') &&
                                  <div className="ac_row" key={key}>
                                    {updatedName == 'upi image' ? <a href={ApiConfig.baseUrl + value} target="_blank" rel="noreferrer"> <img alt="payment_method" src={ApiConfig.baseUrl + value} width="100px" height='100px' /> </a> :
                                      <>
                                        <small> {updatedName} </small>
                                        <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                  </div>
                                )
                              })

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(orderDetails?.side === 'BUY' && orderDetails?.postAd_user == Id) && <div className="timeline_data" >
                    <h5> Once the funds have been transferred, click ( Transfer Completed, Notify Seller )<br /> <small>(Only pay via payment method you have selected while creating post)</small></h5>
                    <div className=" pt-3 pt-md-3">
                      <div className=" " >
                        <button type="button" className="btn custom-btn btn-success  btn-shadow-soft " disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false} onClick={() => { notifySellerMerchent("CONFIRMED", orderId) }}>Transfer Completed, Notify Seller </button>
                        <button type="button" className="btn custom-btn custom-border-btn  custom-border-btn-white   ms-2  " disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false} onClick={() => handleRunTime("CANCELLED", orderId)}><span>Cancel</span></button>
                      </div>
                    </div>
                  </div>}
                  {(orderDetails?.side === 'BUY' && orderDetails?.trader_id == Id) && <div className="timeline_data" >
                    <h5>Did you recieve the payment ?</h5>
                    <div className=" pt-3 pt-md-3">
                      <div className=" " >
                        <button type="button" className="btn custom-btn btn-success  btn-shadow-soft px-5" disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={() => { notifySellerTrader("CONFIRMED", orderId); $("#rating_modal").modal('show') }}>Yes</button>

                        <button type="button" className="btn text-danger shadow-soft  custom-btn custom-border-btn  custom-border-btn-white  mx-2 px-5" disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={() => notifySellerTrader("DISPUTE", orderId)}><span>No</span></button>
                      </div>
                    </div>
                  </div>}

                  {(orderDetails?.side === 'SELL' && orderDetails?.postAd_user == Id) && <div className="timeline_data" >
                    <h5>Did you recieve the payment ?</h5>
                    <div className=" pt-3 pt-md-3">
                      <div className=" " >
                        <button type="button" className="btn custom-btn btn-success  btn-shadow-soft  px-5 " disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false} onClick={() => { notifySellerMerchent("CONFIRMED", orderId); $("#rating_modal").modal('show') }}>Yes</button>

                        <button type="button" className="btn text-danger shadow-soft   custom-btn custom-border-btn  custom-border-btn-white  mx-2  px-5 " disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false} onClick={() => notifySellerMerchent("DISPUTE", orderId)}><span>No</span>
                        </button>
                      </div>
                    </div>
                  </div>}

                  {(orderDetails?.side === 'SELL' && orderDetails?.trader_id == Id) && <div className="timeline_data" >
                    <h5> Once the funds have been transferred, click ( Transfer Completed, Notify Seller )<br /><small>(Only pay on above mentioned payment method)</small> </h5>
                    <div className=" pt-3 pt-md-3">
                      <div className=" " >
                        <button type="button" className="btn custom-btn btn-success  btn-shadow-soft " disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={() => { notifySellerTrader("CONFIRMED", orderId) }}>Transfer Completed, Notify Seller</button>

                        <button type="button" className="btn custom-btn custom-border-btn  custom-border-btn-white   ms-2  " disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={() => handleRunTime("CANCELLED", orderId)}><span>Cancel</span></button>
                      </div>
                    </div>
                  </div>}

                </div>
              </div>
              <hr />
            </div>

            {frndId &&
              <div className="col-lg-5">
                <Messenger frndId={frndId} orderId={orderId} orderStatus={orderDetails?.status} />
              </div>}

          </div>
        </div>
      </div>

      <div className="modal fade" id="rating_modal" tabIndex="-1" aria-labelledby="rating_modalLaebl" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
          <div className="modal-content p-2 pb-5" style={{ display: "flex", alignItems: "center" }}>
            <div className="modal-header flex-column px-8" style={{ borderBottom: "none" }}>
              <h3 className="modal-title" id="placeBitLaebl">Please Rate the order experience</h3>
            </div>
            <div>
              {[1, 2, 3, 4, 5].map((star) => (<span key={star} style={{ cursor: 'pointer', color: star <= rating ? 'gold' : 'gray' }} onClick={() => handleRatingChange(star)} >★</span>))}
            </div>

            <p>You rated: {rating}</p>
            <button type="button" className="btn btn-gradient" data-bs-dismiss="modal" aria-label="Close" onClick={() => rateHandler(rating)}>Submit</button>


          </div>
        </div>
      </div>

    </>
  );
};

export default BuyPTwop;
