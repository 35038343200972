import { ApiConfig } from "../../Api/Api_Config/ApiEndpoints";
import { makeApiRequest2, parseFullSymbol } from "./helpers";
const { io } = require("socket.io-client");

const channelToSubscription = new Map();
let socket = io(`${ApiConfig?.webSocketUrl}`, { transports: ['websocket'], upgrade: false, rejectUnauthorized: false, reconnectionAttempts: 5, debug: true, });
let checkCurrPair;
let unsubscribe;
let interval;

export async function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, lastDailyBar) {
	const channelString = symbolInfo.name;
	const handler = { id: subscriberUID, callback: onRealtimeCallback };
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) { subscriptionItem.handlers.push(handler); return; }

	subscriptionItem = { subscriberUID, resolution, lastDailyBar, handlers: [handler], };

	channelToSubscription.set(channelString, subscriptionItem);

	const parsedSymbol = parseFullSymbol(symbolInfo?.name);
	checkCurrPair = `${parsedSymbol?.fromSymbol}/${parsedSymbol?.toSymbol}`;

	unsubscribeFromPreviousStreams();

		await handleGenericSubscription(parsedSymbol, subscriptionItem, onRealtimeCallback);
}

function unsubscribeFromPreviousStreams() {
	closePreviousSocketIO();
};

function closePreviousSocketIO() {
	if (interval) {
		clearInterval(interval);
		interval = null;
	}
	if (socket) {
		socket.off('message');
	}
}


async function handleGenericSubscription(parsedSymbol, subscriptionItem, onRealtimeCallback) {
	const ApiData = await makeApiRequest2(parsedSymbol.fromSymbol, parsedSymbol.toSymbol);
	const CoinID = ApiData.currency_ids;
	const historicalDataLength = ApiData.count;
	const payload = {
		message: 'exchange',
		base_currency_id: CoinID.base_currency_id,
		quote_currency_id: CoinID.quote_currency_id,
		cursor: historicalDataLength,
	};

	socket.emit('message', payload);

	if (!unsubscribe) {
		interval = setInterval(() => {
			socket.emit('message', payload);
		}, 5000);
		unsubscribe = checkCurrPair;
	} else if (unsubscribe !== checkCurrPair) {
		clearInterval(interval);
		interval = setInterval(() => {
			socket.emit('message', payload);
		}, 5000);
		unsubscribe = checkCurrPair;
	}

	socket.on('message', (data) => {
		handleGenericMessage(data, parsedSymbol, subscriptionItem, onRealtimeCallback);
	});
}

function handleGenericMessage(data, parsedSymbol, subscriptionItem, onRealtimeCallback) {
	const currPair = data?.pairs?.find(item => item?.base_currency === parsedSymbol.fromSymbol && item?.quote_currency === parsedSymbol.toSymbol);
	if (!currPair) return;
	let changeMiliSecond = currPair?.available === "LOCAL" ? 1000 : 1

	const tickerData = data?.ticker?.[data?.ticker?.length - 1];
	if (!tickerData) return;

	const tradeTime = tickerData.time;
	const volume = tickerData.volume;
	const tradePrice = tickerData.close;

	const lastDailyBar = subscriptionItem.lastDailyBar;
	if (!lastDailyBar) return;

	const lastBarTime = getStartOfMinute(lastDailyBar.time);
	const currentTradeMinute = getStartOfMinute(tradeTime * changeMiliSecond);
	let bar;

	if (currentTradeMinute > lastBarTime) {
		bar = {
			time: tradeTime* changeMiliSecond,
			open: lastDailyBar.close,
			high: tradePrice,
			low: tradePrice,
			close: tradePrice,
			// volume: volume,
		};
	} else {
		bar = {
			...lastDailyBar,
			high: Math.max(lastDailyBar.high, tradePrice),
			low: Math.min(lastDailyBar.low, tradePrice),
			close: tradePrice,
			// volume: volume,
		};
	}

	subscriptionItem.lastDailyBar = bar;
	onRealtimeCallback(bar);
}

function getStartOfMinute(timestamp) {
	const date = new Date(timestamp);
	date.setSeconds(0, 0);
	return date.getTime();
}

export function unsubscribeFromStream(subscriberUID) {
	for (const [channelString, subscriptionItem] of channelToSubscription.entries()) {
		const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID);
		if (handlerIndex !== -1) {
			subscriptionItem.handlers.splice(handlerIndex, 1);
			if (subscriptionItem.handlers.length === 0) {
				channelToSubscription.delete(channelString);
				if (checkCurrPair === subscriptionItem.subscriberUID.split('_')[0]) {
					clearInterval(interval);
					// if (webSocket) {
					// 	webSocket.close();
					// }
				}
				break;
			}
		}
	}
}
