import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton, LinkedinIcon, FacebookIcon, WhatsappIcon } from "react-share";
import AuthService from "../../Api/Api_Services/AuthService";
import { alertSuccessMessage } from "../../Utils/CustomAlertMessage";
import copy from 'copy-to-clipboard';
import { deployedUrl } from "../../Api/Api_Config/ApiEndpoints";
import { ProfileContext } from "../../Context";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";

const ReferralPage = () => {
  const [totalReferrals, setTotalReferrals] = useState({ count: 0, reward: 0 });
  const [referralLink, setReferralLink] = useState("");
  const { wccexPrice } = useContext(ProfileContext)



  const handleReferralAmount = async (link) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.userReferralList(link).then(async (result) => {
      if (result?.success) {
        let totalReward = result?.data?.filter((item) => item?.wccex_reward == "true")
        setTotalReferrals({ count: result?.data?.length || 0, reward: totalReward?.length || 0 });
      }
    });
  };

  const handleReferralLink = async () => {
    LoaderHelper.loaderStatus(true)
    await AuthService.referralLink().then(async (result) => {
      if (result?.success) {
        setReferralLink(result?.data);
        handleReferralAmount(result?.data)
      }
    });
  };

  const toFixedTwo = (value) => {
    return parseFloat(value?.toFixed(2))
}

  useEffect(() => {
    handleReferralLink();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  const link = `${deployedUrl}signup?reffcode=${referralLink}`;
  const msg = `Get ${wccexPrice * 2} WCCEXC worth 2$ on WCCEXC Sign Up Now | Valid for the first 50000 Sign Ups. Refer your friends to earn 10 WCCEXC worth 6$ on every signup & 50% of the commission fee generated by your invites.`;


  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title text-gradient">WCCEXC Referral Program</h1>
            <span className="partnerx-hero-description-title pt-3">
              WCCEXC presents you first ever exchange that will educate you
              about Crypto Trading and learning. Start your trading journey now!
            </span>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/profile/Settings">My Account</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Referral Program
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className="bg-1">
        <section className=" invite_sec logg_inn bg_img">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 mx-auto">
                <div className="card">
                  <div className="card-body invite_card ">
                    <div className="text-center">
                      <h4>
                        WCCEXC EXCHANGE WILL BE LIVE AFTER 50000 SIGN-UPS
                      </h4>
                      <p className=" ">
                        {` Refer your friends to earn ${toFixedTwo(wccexPrice * 2)}  WCCEXC worth 2$ on every signup.`}
                      </p>
                    </div>
                    <br />

                    <hr />
                    <br />

                    <div className="congr_sec">
                      <h4>Congratulations!</h4>
                      <p>
                        {`  You have been registered & verified successfully you've
                        earned  ${toFixedTwo(wccexPrice * 0.25)} WCCEXC as a signup bonus.`}
                      </p>

                      <div className="token_share">
                        <a href="#/s" className="button is-play">
                          <img alt="" src="/images/favicon/favicon.png" width="100" height="100" />
                          <div className="button-outer-circle has-scale-animation"></div>
                          <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                        </a>

                        <h3 className="earned_points text-gradient">{toFixedTwo(wccexPrice * 0.25)} WCCEXC</h3>
                        <small>You have earned</small>
                      </div>
                    </div>
                    <h5>
                      <i className="fa fa-link"></i> Refer & Earn</h5>
                    <form data-copy="true">
                      <input type="text" value={`${deployedUrl}signup?reffcode=${referralLink}`} data-click-select-all readOnly />
                      <button type="button" style={{ cursor: "pointer" }} value="copy" onClick={() => { copy(link); alertSuccessMessage("Refferal Link Copied") }}>
                        <i className="ri-file-copy-line"></i>
                      </button>
                    </form>
                    <div className="share" style={{ columnGap: "2rem" }}>
                      <FacebookShareButton url={link} title={msg}>
                        <FacebookIcon size={40} round />
                      </FacebookShareButton>

                      <LinkedinShareButton url={link} title={msg}>
                        <LinkedinIcon size={40} round />
                      </LinkedinShareButton>

                      <WhatsappShareButton url={link} title={msg}>
                        <WhatsappIcon size={40} round />
                      </WhatsappShareButton>
                    </div>
                    <div className="hurryup text-center mb-4">
                      <p className=" ">
                        Hurry up and don’t miss the opportunity to earn free
                        Crypto. It's your time to be a part of <br /> world
                        leading Crypto Learning, Trading & Earning platform.
                      </p>
                      <br />
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-md-4">
                        <div className="bl_card h-100">
                          <img alt="" src="/images/download_1.png" className="img-fluid" />
                          <h3 className="">
                            <small>Total Referrals</small>
                            {totalReferrals?.count}
                          </h3>
                          <Link to="/ReferralList" className=" text-gradient mt-3 decoration-underline" >
                            View Referal List
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="bl_card  h-100">
                          <img alt="" src="/images/download_2.png" className="img-fluid" />
                          <h3 className="">
                            <small>Total Rewards</small>
                            {toFixedTwo(totalReferrals?.reward * wccexPrice * 2)} WCCEXC
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="partnerx-section partnerx-hero">
          <div className="partnerx-hero-bullets">
            <ul className="partnerx-hero-bullets-list">
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/50.png" alt="Bullet-1" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Earn 50% as reward of every trading fee
                </span>
              </li>
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/cash-on-delivery.png" alt="Bullet-2" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Payout every 24 hours!
                </span>
              </li>
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/bitcoin_new.png" alt="Bullet-3" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Unlimited referrals
                </span>
              </li>
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/money.png" alt="Bullet-4" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Unlimited rewards
                </span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReferralPage;
