import React from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "../Components/LandingPage";
import UserHeader from "../Layout/UserHeader";
import LoginPage from "../Components/LoginPage";
import AuthHeader from "../Layout/AuthHeader";
import SignupPage from "../Components/SignupPage";
import Footer from "../Layout/Footer";
import AccountPage from "../Layout/Sidebar";
import TradePage from "../Components/TradePage";
import MarketPage from "../Components/MarketPage";
import ComingSoonPage from "../Utils/ComingSoonPage";
import FundPage from "../Components/FundPage";
import KycPage from "../Components/KycPage";
import TermsOfUsePage from "../Layout/FooterPages/TermsOfUsePage";
import Career from "../Layout/FooterPages/CareerPage";
import Support from "../Layout/FooterPages/Support";
import FAQ from "../Layout/FooterPages/FAQ";
import AboutUs from "../Layout/FooterPages/AboutUs";
import PrivacyPolicyPage from "../Layout/FooterPages/PrivacyPolicyPage";
import ForgotPassPage from "../Components/ForgotPassPage";
import ReferralPage from "../Components/ReferralPage";
import ReferralList from "../Components/ReferralList";
import FAQSidebar from "../Layout/FooterPages/FAQ/FAQSidebar";
import Announcements from "../Layout/FooterPages/Announcements";
import Careerlist from "../Layout/FooterPages/CareerPage/Careerlist";
import AnnouncementList from "../Layout/FooterPages/Announcements/AnnouncementList";
import AnnouncementListDetails from "../Layout/FooterPages/Announcements/AnnouncementListDetails";
import CoinApply from "../Layout/FooterPages/CoinListing/CoinApply";
import BlogList from "../Layout/FooterPages/Blog/BlogList";
import BlogDetails from "../Layout/FooterPages/Blog/BlogDetails";
import CoinListings from "../Layout/FooterPages/CoinListing";
import Earn from "../Layout/FooterPages/Earn";
import JobDiscription from "../Layout/FooterPages/CareerPage/JobDiscription";
import JobApply from "../Layout/FooterPages/CareerPage/JobApply";
import RiskDisclosure from "../Layout/FooterPages/RiskDisclosure";
import WCCEXVentures from "../Layout/FooterPages/Ventures";
import VIPServices from "../Layout/FooterPages/VIPServices";
import DownloadWCCEX from "../Layout/FooterPages/Download";
import Fees from "../Layout/FooterPages/Fees";
import BuyCrypto from "../Layout/FooterPages/BuyCrypto";
import LawEnforcement from "../Layout/FooterPages/LawEnforcementGuide";
import HowToBuy from "../Layout/FooterPages/HowToBuy";
import LawEnforcementReq from "../Layout/FooterPages/LawEnforcementReq";
import CryptoDetails from "../Layout/FooterPages/CryptoInformation/CryptoDetails";
import CryptoInformation from "../Layout/FooterPages/CryptoInformation";
import Affiliate from "../Layout/FooterPages/Affiliate";
import SettingsPage from "../Components/SettingsPage";
import TwofactorPage from "../Components/TwofactorPage";
import CurrencyPrefrence from "../Components/CurrencyPrefrence";
import ActivitylogPage from "../Components/ActivitylogPage";
import ErrorPage from "../Utils/404Page";
import BuySell from "../Components/QuickBuySell";
import Staking from "../Components/Staking";
import Launchpad from "../Components/Launchpad";
import LaunchpadDetailPage from "../Components/LaunchpadDetailPage";
import SecurityPage from "../Components/SecurityPage";
import NotificationPage from "../Components/NotificationPage";
import CommitDetails from "../Components/LaunchpadDetailPage/CommitDetails";
import MobileChart from "../Components/MobileChart";
import PTwoP from "../Components/PTwoP";
import MyPost from "../Components/PTwoP/MyPost";
import NewPost from "../Components/PTwoP/NewPost";
import BuyPTwop from "../Components/PTwoP/BuyPTwop";
import MyOrders from "../Components/PTwoP/MyOrders";
import PaymentMethod from "../Components/PTwoP/PaymentMethod";
import SupportPage from "../Components/P2PSupport";
const Routing = () => {

  return (
    <>
      {/*   ********* Component AlertBox **********  */}
      <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={3} theme="light" />

      <HeaderComponent />
      <Routes >
        {/* <Route exact path="/*" element={<MaitancePage />}></Route> */}
        <Route exact path="/" element={<LandingPage />}></Route>
        <Route exact path="/*" element={<ErrorPage />}></Route>
        <Route exact path="/comingsoon" element={<ComingSoonPage />}></Route>
        <Route exact path="/trade/:pairs" element={<TradePage />}></Route>
        <Route exact path="/chart/:pairs" element={<MobileChart />}></Route>
        <Route exact path="/MarketPage" element={<MarketPage />}></Route>
        <Route exact path="/Career" element={<Career />}></Route>
        <Route exact path="/AboutUs" element={<AboutUs />}></Route>
        <Route exact path="/mobAboutUs" element={<AboutUs />}></Route>
        <Route exact path="/FAQ" element={<FAQ />}></Route>
        <Route exact path="/RiskDisclosure" element={<RiskDisclosure />}></Route>
        <Route exact path="/WCCEXVentures" element={<WCCEXVentures />}></Route>
        <Route exact path="/LawEnforcementGuide" element={<LawEnforcement />}></Route>
        <Route exact path="/LawEnforcementReq" element={<LawEnforcementReq />}></Route>
        <Route exact path="/VIPServices" element={<VIPServices />}></Route>
        <Route exact path="/affiliates" element={<Affiliate />}></Route>
        <Route exact path="/CryptoDetails" element={<CryptoDetails />}></Route>
        <Route exact path="/CryptoInformation" element={<CryptoInformation />}></Route>
        <Route exact path="/DownloadWCCEX" element={<DownloadWCCEX />}></Route>
        <Route exact path="/BuyCrypto" element={ <BuyCrypto />}></Route>
        <Route exact path="/Fees" element={<Fees />}></Route>
        <Route exact path="/how_to_buy" element={<HowToBuy />}></Route>
        <Route exact path="/PrivacyPolicyPage" element={<PrivacyPolicyPage />}></Route>
        <Route exact path="/mobPrivacyPolicyPage" element={<PrivacyPolicyPage />}></Route>
        <Route exact path="/Support" element={<Support />}></Route>
        <Route exact path="/mobSupport" element={<Support />}></Route>
        <Route exact path="/Earn" element={<Earn />}></Route>
        <Route exact path="/TermsOfUsePage" element={<TermsOfUsePage />}></Route>
        <Route exact path="/mobTermsOfUsePage" element={<TermsOfUsePage />}></Route>
        <Route exact path="/FAQSidebar" element={<FAQSidebar />}></Route>
        <Route exact path="/announcements" element={<Announcements />}></Route>
        <Route exact path="/careerlist" element={<Careerlist />}></Route>
        <Route exact path="/announcementlist" element={<AnnouncementList />}></Route>
        <Route exact path="/announcementlistdetails" element={<AnnouncementListDetails />}></Route>
        <Route exact path="/listingcoin" element={<CoinListings />}></Route>
        <Route exact path="/coinapply" element={<CoinApply />}></Route>
        <Route exact path="/bloglist" element={<BlogList />}></Route>
        <Route exact path="/blogdetails" element={<BlogDetails />}></Route>
        <Route exact path="/job_discription" element={<JobDiscription />}></Route>
        <Route exact path="/job_apply" element={<JobApply />}></Route>

        <Route exact path="/login" element={<NotProtectedRoutes> <LoginPage /> </NotProtectedRoutes>}></Route>
        <Route exact path="/ForgotPassPage" element={<NotProtectedRoutes><ForgotPassPage /> </NotProtectedRoutes>}></Route>
        <Route exact path="/signup" element={<NotProtectedRoutes><SignupPage /> </NotProtectedRoutes>}></Route>

        {/* P2P Routes */}
        <Route exact path="/p2p_trading" element={<ProtectedRoutes><PTwoP /> </ProtectedRoutes>}></Route>
        <Route exact path="/add_post" element={<ProtectedRoutes><NewPost /> </ProtectedRoutes>}></Route>
        <Route exact path="/MyPost" element={<ProtectedRoutes><MyPost /> </ProtectedRoutes>}></Route>
        <Route exact path="/my_orders" element={<ProtectedRoutes><MyOrders /> </ProtectedRoutes>}></Route>
        <Route exact path="/buyPTwop_details/:orderId" element={<ProtectedRoutes><BuyPTwop /> </ProtectedRoutes>}></Route>
        <Route exact path="/PaymentMethod" element={<ProtectedRoutes><PaymentMethod /> </ProtectedRoutes>}></Route>


        {/* ********** Protected Routes (After Login Routes)********** */}
        <Route exact path="/FundPage" element={<ProtectedRoutes><FundPage /> </ProtectedRoutes>}></Route>
        <Route exact path="/ReferralPage" element={<ProtectedRoutes><ReferralPage /></ProtectedRoutes>}></Route>
        <Route exact path="/launchpad-details/:id" element={<ProtectedRoutes><LaunchpadDetailPage /> </ProtectedRoutes>}></Route>
        <Route exact path="/Launchpad" element={<ProtectedRoutes><Launchpad /> </ProtectedRoutes>}></Route>
        <Route exact path="/Staking" element={<ProtectedRoutes><Staking /> </ProtectedRoutes>}></Route>
        <Route exact path="/ReferralList" element={<ProtectedRoutes><ReferralList /> </ProtectedRoutes>}></Route>
        <Route exact path="/BuySell" element={<ProtectedRoutes><BuySell /></ProtectedRoutes>}></Route>
        <Route exact path="/KycPage" element={<ProtectedRoutes><KycPage /> </ProtectedRoutes>}></Route>
        <Route exact path="/SupportPage" element={<ProtectedRoutes><SupportPage /> </ProtectedRoutes>}></Route>

        <Route exact path="/CommitDetails/:projectId" element={<ProtectedRoutes><CommitDetails /> </ProtectedRoutes>}></Route>
        <Route exact path="/Profile" element={<ProtectedRoutes> <AccountPage /></ProtectedRoutes>}>
          <Route exact path="Settings" element={<SettingsPage />}></Route>
          <Route exact path="CurrencyPrefrence" element={<CurrencyPrefrence />}></Route>
          <Route exact path="Twofactor" element={<TwofactorPage />}></Route>
          <Route exact path="Activitylog" element={<ActivitylogPage />}></Route>
          <Route exact path="SecurityPage" element={<SecurityPage />}></Route>
          <Route exact path="Notifications" element={<NotificationPage />}></Route>

        </Route>

      </Routes >
      <FooterWithoutTrade />
    </>
  );
};

function FooterWithoutTrade() {
  const location = useLocation();
  const isTradePage = location?.pathname?.includes('/trade');
  const isMobPrivacyPolicyPage = location?.pathname?.includes('/mobPrivacyPolicyPage');
  const isMobSupport = location?.pathname?.includes('/mobSupport');
  const isChartPage = location?.pathname?.includes('/chart');
  const isAboutUs = location?.pathname?.includes('/mobAboutUs');
  const isTerms = location?.pathname?.includes('/mobTermsOfUsePage');

  return !isTradePage && !isMobPrivacyPolicyPage && !isMobSupport && !isChartPage && !isAboutUs && !isTerms ? <Footer /> : null;
};

function ProtectedRoutes({ children }) {
  const AuthToken = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
  return AuthToken ? children : <LoginPage />

};

function NotProtectedRoutes({ children }) {
  const AuthToken = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
  return !AuthToken ? children : <AccountPage />
};

function HeaderComponent() {
  const location = useLocation();
  const AuthToken = sessionStorage.getItem("WCCEX_AUTH_TOKEN");
  const isMobSupport = location?.pathname?.includes('/mobSupport');
  const isMobPrivacyPolicyPage = location?.pathname?.includes('/mobPrivacyPolicyPage');
  const isChartPage = location?.pathname?.includes('/chart');
  const isAboutUs = location?.pathname?.includes('/mobAboutUs');
  const isTerms = location?.pathname?.includes('/mobTermsOfUsePage');

  return (isMobPrivacyPolicyPage || isMobSupport || isChartPage || isAboutUs || isTerms) ? null : !AuthToken ? <UserHeader /> : <AuthHeader />
};

export default Routing;
